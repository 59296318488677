<template>
    <div id="car-types">
        <v-container class="pt-10">
            <v-row
                data-aos="fade-up" 
                data-aos-anchor-placement="top-center"
                data-aos-easing="ease-in-out-sine"
                data-aos-duration="500"
            >
                <v-col cols="12" class="title text-center">
                    Car Types that support different play styles!
                </v-col>   
            </v-row>
            <v-row
                data-aos="fade" 
                data-aos-anchor-placement="top-center"
                data-aos-easing="ease-in-out-sine"
                data-aos-duration="500"
            >
                <v-col cols="12">
                    <v-carousel hide-delimiters height="auto">
                        <v-carousel-item>
                            <div class="d-flex justify-center">
                                <img  src="~@/assets/tuner.png" style="max-width: 100%;" />
                            </div>
                        </v-carousel-item>
                        <v-carousel-item>
                            <div class="d-flex justify-center">
                                <img  src="~@/assets/muscle.png" style="max-width: 100%;" />
                            </div>
                        </v-carousel-item>
                        <v-carousel-item>
                            <div class="d-flex justify-center">
                                <img  src="~@/assets/hybrid.png" style="max-width: 100%;" />
                            </div>
                        </v-carousel-item>
                        <v-carousel-item>
                            <div class="d-flex justify-center">
                                <img  src="~@/assets/super.png" style="max-width: 100%;" />
                            </div>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
           </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
  
}
</script>


<style lang="scss">
#car-types {
    min-height: 250px;
    background: rgb(19, 19, 19) !important;
    color: rgb(89, 89, 89);
    width: 100%;
    
    .title {
        font-family: 'Airbolt' !important;
        font-size: 39px !important;
    }
}



</style>