<template>
    <v-app>
        <div class="site-loading">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "App",

    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Outrace',
      // all titles will be injected into this template
      titleTemplate: '%s | Play-to-Earn',

        meta: [
            {
                vmid: "og:image",
                name: "og:image",
                content: window.location + '/img/icons/favicon-32x32.png',
            },
        ]
    },

    data: () => ({
        window_preloader: true
    }),

    created() {
        window.addEventListener("load", event => {
            gsap.to('.site-loading', { autoAlpha: 0})
        });
    }
};
</script>
