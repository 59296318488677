import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import publicRoutes from "./public";

const routes = [
    ...publicRoutes,
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
