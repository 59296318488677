import './sass/main.scss'
import '@fortawesome/fontawesome-free/css/all.css'

import Vue from 'vue'
import Meta from 'vue-meta'


import gsap from 'gsap'



window.gsap = gsap

import eventBus from './helpers/event-bus'

window.eventBus = eventBus

Vue.use(Meta)

import AOS from 'aos'
import 'aos/dist/aos.css';

AOS.init();