<template>
   <div id="home">
       <header-section />
       <navigation-drawer />
       <main-section />
       <what-is-outrace />
       <mission-and-vision />
       <game-platform />
       <game-modes />
       <car-types />
       <outrace-cards />
       <youtube-videos />
       <featured-in />
       <tokenomics />
       <our-team />
       <footer-bar />
   </div>
</template>

<script>
import HeaderSection from '../../partials/Header.vue'
import NavigationDrawer from '../../partials/NavigationDrawer.vue'
import MainSection from '../home-parts/MainSection.vue'
import WhatIsOutrace from '../home-parts/WhatIsOutrace.vue'
import MissionAndVision from '../home-parts/MissionAndVision.vue'
import GamePlatform from '../home-parts/GamePlatform.vue'
import GameModes from '../home-parts/GameModes.vue'
import CarTypes from '../home-parts/CarTypes.vue'
import OutraceCards from '../home-parts/OutraceCards.vue'
import YoutubeVideos from '../home-parts/YoutubeVideos.vue'
import FeaturedIn from '../home-parts/FeaturedIn.vue'
import Tokenomics from '../home-parts/Tokenomics.vue'
import OurTeam from '../home-parts/OurTeam.vue'
import FooterBar from '../home-parts/Footer.vue'

export default {
    name: "Home",

    components: {
       HeaderSection,
       NavigationDrawer,
       MainSection,
       WhatIsOutrace,
       MissionAndVision,
       GamePlatform,
       GameModes,
       CarTypes,
       OutraceCards,
       YoutubeVideos,
       FeaturedIn,
       Tokenomics,
       OurTeam,
       FooterBar
    },
};
</script>