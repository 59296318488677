<template>
    <v-app-bar dark app>
        <v-toolbar elevation="0" class="d-block d-md-flex justify-center">
            <div id="drawer-button-wrapper" class="d-block d-md-none" @click="toggleNavigationDrawer">
                <v-icon color="white">
                    {{ show_drawer ? "fas fa-times" : "fas fa-bars" }}
                </v-icon>
            </div>
            <v-img src="@/assets/tapgear-logo-main-banner.png" class="mr-0 mr-md-5 d-block d-md-none" contain width="100px"
                height="50px" />
            <div id="navigation-wrapper" class="d-none d-md-flex justify-center align-center">
                <v-img src="@/assets/tapgear-logo-main-banner.png" class="mr-0 mr-md-5" contain width="100px" height="60px" />
                <ul id="navigation">
                    <li v-for="(navigation, index) in navigations" :key="index">
                        <a :href="playNowLink" v-if="navigation.name == 'play-now'" :class="navigation.special && 'special'"
                            :target="navigation.external ? '_blank' : ''">{{ navigation.title }}</a>

                        <a :href="navigation.link" v-else :class="navigation.special && 'special'"
                            :target="navigation.external ? '_blank' : ''">{{ navigation.title }}</a>
                    </li>
                </ul>
            </div>
        </v-toolbar>
    </v-app-bar>
</template>
<script>
export default {
    data: () => ({
        show_drawer: false,
        navigations: [
            {
                name: "buy-token",
                link: "https://pancakeswap.finance/swap?outputCurrency=0x91F006ee672F8f39C6E63cA75B1cA14067b3c366",
                title: "Buy Token",
                special: false,
                external: true,
            },
            {
                name: "features",
                link: "#game-modes",
                title: "Features",
                special: false,
                external: false,
            },
            {
                name: "play-now",
                link: "https://d2bms56usrbl58.cloudfront.net/outrace-v0.8.3.zip",
                title: "Play Now",
                special: true,
                external: true,
            },
            {
                name: "market-place",
                link: "https://marketplace.outrace.app/",
                title: "Market Place",
                special: false,
                external: true,
            },
            {
                name: "our-team",
                link: "#our-team",
                title: "Our Team",
                special: false,
            },
            {
                name: "white-paper",
                link: "https://drive.google.com/file/d/1IwQgxufL_liXyrabSQVUAeyWSRWuXIHg/view",
                title: "Whitepaper",
                special: false,
                external: true,
            },
        ],
    }),

    computed: {
        playNowLink() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                return "https://d2bms56usrbl58.cloudfront.net/outrace-v0.8.3.apk";
            }

            return "https://d2bms56usrbl58.cloudfront.net/outrace-v0.8.3.zip";
        },
    },

    methods: {
        toggleNavigationDrawer() {
            window.eventBus.$emit("toggle-navigation-drawer");
            this.show_drawer = !this.show_drawer;
        },
    },
};
</script>
<style lang="scss">
#drawer-button-wrapper {
    cursor: pointer;
}

#navigation-wrapper {
    height: 100% !important;

    #navigation {
        height: 100%;
        padding: 0;
        margin: 0;

        >li {
            height: 100%;
            position: relative;
            display: inline-flex;
            align-items: center;

            a {
                line-height: 100%;
                text-decoration: none;
                padding: 10px 20px;
                color: rgb(237, 237, 237);
                font-weight: bold;
                font-family: "Airbolt";
                font-size: 15px;
            }

            a.special {
                font-size: 35px;
                color: rgb(255, 167, 60);
            }
        }
    }
}

@media screen and (max-width: 1264px) {
    #navigation-wrapper {
        #navigation {
            >li {
                a {
                    padding: 10px 10px;
                    color: rgb(237, 237, 237);
                    font-weight: bold;
                    font-family: "Airbolt";
                    font-size: 12px;
                }

                a.special {
                    font-size: 25px;
                    color: rgb(255, 118, 60);
                }
            }
        }
    }
}
</style>