<template>
    <div id="navigation-drawer" :class="drawer_status">
        <ul id="navigation">
            <a v-for="(navigation, index) in navigations" :key="index" :href="navigation.link"
                :class="navigation.special && 'special'">
                <li>
                    <a :href="playNowLink" v-if="navigation.name == 'play-now'" :class="navigation.special && 'special'"
                        :target="navigation.external ? '_blank' : ''">{{ navigation.title }}</a>

                    <a v-else :href="navigation.link" :class="navigation.special && 'special'"
                        :target="navigation.external ? '_blank' : ''">{{ navigation.title }}</a>
                </li>
            </a>
        </ul>
    </div>
</template>
<script>
export default {
    data: () => ({
        show: false,
        drawer_status: "d-none",
        navigations: [
            {
                name: "buy-token",
                link: "https://pancakeswap.finance/swap?outputCurrency=0x91F006ee672F8f39C6E63cA75B1cA14067b3c366",
                title: "Buy Token",
                special: false,
                external: true,
            },
            {
                name: "features",
                link: "#features",
                title: "Features",
                special: false,
            },
            {
                name: "market-place",
                link: "https://marketplace.outrace.app/",
                title: "Market Place",
                special: false,
                external: true,
            },
            {
                name: "our-team",
                link: "#our-team",
                title: "Our Team",
                special: false,
            },
            {
                name: "white-paper",
                link: "https://drive.google.com/file/d/1IwQgxufL_liXyrabSQVUAeyWSRWuXIHg/view",
                title: "Whitepaper",
                special: false,
                external: true,
            },
            {
                name: "play-now",
                link: "https://d2bms56usrbl58.cloudfront.net/outrace-v0.8.3.zip",
                title: "Play Now",
                special: true,
                external: true,
            },
        ],
    }),

    computed: {
        playNowLink() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                return "https://d2bms56usrbl58.cloudfront.net/outrace-v0.8.3.apk";
            }

            return "https://d2bms56usrbl58.cloudfront.net/outrace-v0.8.3.zip";
        },
    },

    watch: {
        show(value) {
            // if (!value) {
            // gsap.from("#navigation li", {x: 0, autoAlpha: 1, stagger: 0.2, overwrite: 'auto'});

            if (value) {
                this.drawer_status = "d-block";
                gsap.timeline()
                    .to("#navigation-drawer", { autoAlpha: 1 })
                    .to("#navigation li", {
                        x: 0,
                        delay: -1.1,
                        stagger: 0.2,
                        overwrite: true,
                    });
            } else {
                gsap.timeline()
                    .to("#navigation li", {
                        x: -250,
                        delay: -1.1,
                        stagger: 0.2,
                        overwrite: true,
                    })
                    .to("#navigation-drawer", { autoAlpha: 0 })
                    .eventCallback("onComplete", () => {
                        this.drawer_status = "d-none";
                    });
            }

            // }
        },
    },

    mounted() {
        window.eventBus.$on("toggle-navigation-drawer", () => {
            this.show = !this.show;
        });
    },
};
</script>
<style lang="scss">
#navigation-drawer {
    z-index: 1000;
    left: 0;
    position: fixed;
    background: rgb(52, 52, 52, 0.2);
    width: 100%;
    height: 100%;

    &::after {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }

    ul#navigation {
        position: absolute;
        z-index: 1000;
        padding: 0;
        margin: 0;
        list-style: none;
        width: 250px;
        height: 100%;

        >a {
            text-decoration: none;
            color: #fff;
            font-family: "Airbolt";

            li {
                transform: translateX(-250px);
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                height: 50px;
                padding: 0px 5px;
                margin: 5px 0px;
                border-bottom: thin solid rgb(255, 118, 60);

                a {
                    text-decoration: none;
                    color: #fff;
                }
            }
        }

        >a.special {
            color: rgb(255, 167, 60);
            font-size: 30px;
        }
    }
}
</style>